import React, {FunctionComponent as FC, useContext} from "react";
import styled from "styled-components";
import {WebsiteSliderItem} from "../../models/web/WebsiteSliderItem";
import {SliderItemContext} from "../../provider/slider-item";
import Error from "../error";
import {SingleImage} from "./single-image";
import {Slider} from "./slider";

const SpinnerWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 180px);
  min-height: 400px;
  position: relative;
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
`;

const HeroArea: FC = () => {
  const {
    sliderItems: sliderItems,
    loading,
    error
  } = useContext(SliderItemContext);

  // If a countdown or visibility date is set, item is only visible after the set date.
  const visibilityFilter = (item: WebsiteSliderItem) => {
    // Hide slider if now is later than the "visibile until" time.
    if (item.visibleUntil && new Date(item.visibleUntil) < new Date()) {
      return false;
    }

    if (item.countdownAfter) {
      return new Date(item.countdownAfter) < new Date();
    } else if (item.visibleAfter) {
      return new Date(item.visibleAfter) < new Date();
    }
    return true;
  };

  if ((loading || !sliderItems) && !error) {
    return (
      <SpinnerWrapper>
        <Spinner className="m-spinner--swirl mb-3"></Spinner>
      </SpinnerWrapper>
    );
  } else if (sliderItems && sliderItems.filter(visibilityFilter).length > 0) {
    const filteredSliderItems = sliderItems.filter(visibilityFilter);
    const firstSlideritem = filteredSliderItems[0];
    
    if (filteredSliderItems.length === 1) {
      return <SingleImage sliderItem={firstSlideritem} />;
    }

    return <Slider sliderItems={filteredSliderItems} />;
  } else {
    // If an error occurs the heroarea will not be rendered.
    return null;
  }
};

export default HeroArea;
