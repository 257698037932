import React, {FunctionComponent as FC, useContext} from "react";
import styled from "styled-components";
import {Navigation, Pagination, Virtual, Autoplay} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/virtual";
import "swiper/css/autoplay";
import {Swiper, SwiperSlide} from "swiper/react";
import {WebsiteSliderItem} from "../../models/web/WebsiteSliderItem";
import {LanguageContext} from "../../provider/language";
import {CtaButton} from "./cta-button";
import {HeroAreaDescription} from "./heroareadescription";
import {TagCloud} from "./tag-cloud";
import Countdown from "./countdown";
import {Content} from "./single-image";

const StyledSwiper = styled(Swiper)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 180px;
  height: calc(100vh - 180px);
  min-height: 400px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
`;

interface BackgroundImageProps {
  imageUrl: string;
}

const BackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  min-height: 400px;
  background: url(${(props) => props.imageUrl}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  --swiper-navigation-color: #252f4a;
  --swiper-pagination-color: #252f4a;
`;

const ImageSizedSpacer = styled.div`
  height: calc(100vh - 180px);
  position: relative;
`;

type SliderProps = {sliderItems: WebsiteSliderItem[]};

export const Slider: FC<SliderProps> = ({sliderItems}) => {
  const {locale} = useContext(LanguageContext);
  const now = new Date();

  const showCountdown = (sliderItem: WebsiteSliderItem) : boolean => {
    return sliderItem.countdownAfter
      && now > new Date(sliderItem.countdownAfter)
      && sliderItem.visibleAfter
      && now < new Date(sliderItem.visibleAfter)
  };

  return (
    <>
      <StyledSwiper
        style={{
          // @ts-ignore
          "--swiper-theme-color": "#252f4a"
        }}
        rewind={true}
        navigation={true}
        pagination={{
          clickable: true
        }}
        speed={1000}
        autoplay={{
          delay: 10000
        }}
        modules={[Navigation, Pagination, Virtual, Autoplay]}
        virtual
      >
        {sliderItems.map((sliderItem, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <BackgroundImage
              imageUrl={sliderItem.image.url}
              role="img"
              aria-label={sliderItem.image.altText[locale]}
            >
              <div className="container">
                {sliderItem.content.tagCloud ? (
                  <TagCloud tagCloud={sliderItem.content.tagCloud} />
                ) : null}
              </div>
            </BackgroundImage>
            <ButtonWrapper>
              {showCountdown(sliderItem) ? (
                <Countdown visibleAfter={sliderItem.visibleAfter} />
              ) : null}
              {sliderItem.ctaButton && sliderItem.ctaButton.caption.de ? (
                <CtaButton
                  url={sliderItem.ctaButton.url[locale]}
                  caption={sliderItem.ctaButton.caption[locale]}
                />
              ) : null}
            </ButtonWrapper>
            <HeroAreaDescription sliderItem={sliderItem} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <ImageSizedSpacer></ImageSizedSpacer>
    </>
  );
};
